'use strict';

(function() {
    require('limitless/css/icons/material-icons/styles.css');
    require('limitless/css/icons/icomoon/styles.css');
    require('limitless/css/icons/fontawesome/styles.min.css');
    require('limitless/css/bootstrap.css');
    require('limitless/css/core.css');
    require('limitless/css/components.css');
    require('limitless/css/colors.css');

    require('src/css/dataTables.checkboxes.css');
    require('src/css/fancytree.filter.css');
    require('src/css/iThing.css');

    require('vue-multiselect/dist/vue-multiselect.min.css');

    require('src/js/lib/pace.js');

    require('jquery');
    require('src/js/lib/jquery-ui-1.12.1.js');

    require('src/js/lib/bootstrap-3.3.7.js');

    require('lodash');

    require('src/js/lib/jquery.blockUI.js');
    require('src/js/lib/jquery.nicescroll-3.6.8.js');
    require('src/js/lib/jquery.dcdrilldown.1.2.js');
    require('src/js/lib/switchery-0.8.1.js');
    require('src/js/lib/select2-4.0.3.js');
    require('src/js/lib/jquery.noty/jquery.noty.packaged-2.4.1.js');
    require('src/js/lib/bootstrap-progressbar.js');

    require('moment');
    require('src/js/lib/moment/moment-range.js');
    require('src/js/lib/moment/moment-duration-format.js');

    require('numeral');

    require('src/js/lib/jquery.fancytree-all.2.29.0.js');

    require('src/js/lib/jqrangeslider/jQRangeSliderMouseTouch.js');
    require('src/js/lib/jqrangeslider/jQRangeSliderDraggable.js');
    require('src/js/lib/jqrangeslider/jQRangeSliderBar.js');
    require('src/js/lib/jqrangeslider/jQRangeSliderHandle.js');
    require('src/js/lib/jqrangeslider/jQRangeSliderLabel.js');
    require('src/js/lib/jqrangeslider/jQRangeSlider.js');
    require('src/js/lib/jqrangeslider/jQDateRangeSliderHandle.js');
    require('src/js/lib/jqrangeslider/jQDateRangeSlider.js');
    require('src/js/lib/jqrangeslider/jQRuler.js');

    require('src/js/lib/numericInput.js');

    require('src/js/lib/jquery.jmHighlight.js');
    require('src/js/lib/jszip-3.1.5.js');
    require('src/js/lib/dataTables/jquery.dataTables-1.10.16.js');
    require('src/js/lib/dataTables/dataTables.select-1.2.5.js');
    require('src/js/lib/dataTables/dataTables.buttons-1.5.1.js');
    require('src/js/lib/dataTables/buttons.html5-1.5.1.js');
    require('src/js/lib/dataTables/dataTables.checkboxes-1.2.9.js');

    require('src/js/lib/jquery.fancybox.js');
    require('src/js/lib/jquery.binarytransport.js');

    require('vue/dist/vue.js');
    Vue.config.devtools = true;
    Vue.config.productionTip = false;

    require('src/js/lib/jquery.mockjax.js');
    require('src/js/lib/jquery.waterfall.js');
    require('src/js/lib/bowser-1.9.2.js');

    require('src/js/lib/jquery.scrollTo.js');
    require('src/js/lib/jquery.scrollLock.js');

    require('flag-icon-css/css/flag-icon.min.css');
})();
