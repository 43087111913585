/**
 *
 * jquery.binarytransport.js
 *
 * @description. jQuery ajax transport for making binary data type requests.
 * @version 1.0
 * @author Henry Algus <henryalgus@gmail.com>
 *
 */

(function ($, undefined) {
    "use strict";

    $.ajaxTransport("+binary", function (options, originalOptions, jqXHR) {
        if (window.FormData && ((options.dataType && (options.dataType == 'binary')) || (options.data && ((window.ArrayBuffer && options.data instanceof ArrayBuffer) || (window.Blob && options.data instanceof Blob))))) {
            return {
                send: function (headers, callback) {
                    var xhr = new XMLHttpRequest(),
                        url = options.url,
                        type = options.type,
                        async = options.async || true,

                    // blob or arraybuffer. Default is blob
                        dataType = options.responseType || "blob",

                        data = options.data || null,
                        username = options.username || null,
                        password = options.password || null;

                    xhr.addEventListener("progress", function (evt) {
                        if(evt.lengthComputable) {
                            var percentComplete = evt.loaded / evt.total;
                            options.progress(Math.round(percentComplete * 100));
                        }
                    }, false);
                    xhr.addEventListener('load', function () {
                        var data = {};
                        data[options.dataType] = xhr.response;

                        if (xhr.status == null || (xhr.status >= 200 && xhr.status < 300 || xhr.status === 304) === false) {
                            if (xhr.response.type === 'application/json') {
                                var reader = new FileReader();
                                reader.addEventListener("loadend", function () {
                                    data = {json: JSON.parse(reader.result)};
                                    callback(xhr.status, xhr.statusText, data, xhr.getAllResponseHeaders());
                                });
                                reader.readAsText(xhr.response);
                            } else {
                                callback(xhr.status, xhr.statusText, data, xhr.getAllResponseHeaders());
                            }
                        } else {
                            callback(xhr.status, xhr.statusText, data, xhr.getAllResponseHeaders());
                        }

                    });
                    xhr.addEventListener('error', function () {
                        var data = {};
                        data[options.dataType] = xhr.response;
                        callback(xhr.status, xhr.statusText, data, xhr.getAllResponseHeaders());
                    });

                    xhr.open(type, url, async, username, password);
                    for (var i in headers) {
                        xhr.setRequestHeader(i, headers[i]);
                    }
                    xhr.responseType = dataType;
                    xhr.send(data);
                },
                abort: function () {
                }
            };
        }
    });
})(window.jQuery);
